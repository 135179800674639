function awsUrl(region: string, project: string): string {
  return `https://${region}.${project}.shared.aws.tidbcloud.com`
}

const _backend = localStorage.getItem('dbaasUrl')
const _dbaasUrl = awsUrl('us-west-2', 'nightly')
export const dbaasUrl: string =
  _backend || process.env.REACT_APP_SERVICE_URL || _dbaasUrl

export function clusterLogsLink(userClusterId: string): string {
  // REACT_APP_AWS_ES_HOST is setup in aws amplify side for build
  const awsESHost = process.env.REACT_APP_AWS_ES_HOST
  const kibanaUrl = `/_plugin/kibana/app/discover?security_tenant=global#/?_a=(columns:!(component,log_file,level,message),filters:!((meta:(key:user_cluster_id,params:(query:'${userClusterId}'),type:phrase),query:(match_phrase:(user_cluster_id:'${userClusterId}')))))`
  const linkUrl = `${awsESHost}/_plugin/kibana/auth/saml/login?nextUrl=${encodeURIComponent(
    kibanaUrl
  )}`
  return linkUrl
}
