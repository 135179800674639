import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Modal, Spin } from 'antd'
import { getAPI, prettyAxiosError, setSession } from 'apiClient'
import { WholePageCenter } from 'components'
import { getRedirectRecord, recoverFromRecordOrRedirect } from 'utils/redirect'

const oAuthRedirectURI = `${window.origin}/auth/callback`

export const SignIn = () => {
  useEffect(() => {
    async function run() {
      try {
        const redirectRecord = getRedirectRecord()
        const config = await getAPI().debugPortalGetOAuthConfig(
          `${oAuthRedirectURI}${redirectRecord ? `?${redirectRecord}` : ''}`
        )
        window.location.replace(config.data.auth_url!)
      } catch (e) {
        Modal.error({
          title: 'Error',
          content: prettyAxiosError(e),
          okText: 'Reload',
          onOk: () => window.location.reload()
        })
      }
    }

    run()
  }, [])

  return (
    <WholePageCenter>
      <div>
        <Spin /> You are not signed in. Redirecting to sign in page...
      </div>
    </WholePageCenter>
  )
}

export const SignInCallback = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    async function run() {
      const { code, state } = Object.fromEntries(
        new URLSearchParams(location.search)
      )
      try {
        const redirectRecord = getRedirectRecord()
        await getAPI().debugPortalExchangeOAuthToken({
          redirect_uri: `${oAuthRedirectURI}${
            redirectRecord ? `?${redirectRecord}` : ''
          }`,
          code,
          state,
          session_key_as_cookie: true
        })
        const sessionInfo = await getAPI().debugPortalGetBasicSession()
        setSession(sessionInfo.data)
        recoverFromRecordOrRedirect('/', true, (to) => {
          const url = new URL(to)
          navigate(`${url.pathname}${url.search}${url.hash}`, { replace: true })
        })
      } catch (e) {
        Modal.error({
          title: 'Authenticate failed',
          content: prettyAxiosError(e),
          okText: 'Sign in again',
          onOk: () => {
            navigate('/login')
          }
        })
      }
    }
    run()
  }, [])

  return (
    <WholePageCenter>
      <div>
        <Spin /> Please wait, we are finishing your authentication...
      </div>
    </WholePageCenter>
  )
}
