import React, { useMemo } from 'react'
import { Alert, Typography } from 'antd'

export function ShutdownAlert() {
  const clinicLink = useMemo(() => {
    const host = window.location.host
    if (host === 'debug.tidbcloud.com') {
      return 'https://clinic.pingcap.com/portal/#/overview'
    }
    return 'http://clinic-staging-1072990385.us-west-2.elb.amazonaws.com:8085/portal/#/overview'
  }, [])

  return (
    <Alert
      type="error"
      style={{ marginBottom: '16px' }}
      message={
        <>
          <span>
            Debug Portal will be{' '}
            <b>
              <Typography.Text type="danger">SHUTDOWN</Typography.Text>
            </b>{' '}
            on 2022-9-6, please go to{' '}
            <a href={clinicLink} target="_blank">
              Clinic
            </a>{' '}
            for more diagnostic features!
          </span>
          <a
            href="https://pingcap.feishu.cn/wiki/wikcnh5CmqJRfa7xfSlTPOm1O6d"
            target="_blank"
          >
            {' '}
            Documentation
          </a>
        </>
      }
    />
  )
}
