import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Result } from 'antd'
import { WholePageCenter } from 'components'

export const SignedOut = () => {
  const navigate = useNavigate()
  return (
    <WholePageCenter>
      <Result
        status="success"
        title="You have been signed out"
        extra={[
          <Button type="primary" onClick={() => navigate('/login')}>
            Sign In Again
          </Button>
        ]}
      />
    </WholePageCenter>
  )
}
