import React, { useState } from 'react'
import { Input, Select } from 'antd'

const { Option } = Select

interface SearchBarProps {
  defaultValue: FilterOption
  options: string[]
  fetcher: (query: FilterOption) => Promise<void>
}

export type FilterOption = { key: string; value: string }

function SearchBar({ defaultValue, options, fetcher }: SearchBarProps) {
  const [queryOption, setQueryOption] = useState(defaultValue.key || options[0])

  const handleOptionChange = (value: string) => {
    setQueryOption(value)
  }

  const handleSearch = (value: string) => {
    fetcher({ key: queryOption, value: value || undefined })
  }

  return (
    <Input.Group compact className="list-search-bar-container">
      <Select
        style={{ width: '30%' }}
        defaultValue={queryOption}
        onChange={handleOptionChange}
      >
        {options.map((o) => {
          return (
            <Option key={o} value={o}>
              {o}
            </Option>
          )
        })}
      </Select>
      <Input.Search
        style={{ width: '70%' }}
        defaultValue={defaultValue.value || ''}
        onSearch={handleSearch}
      />
    </Input.Group>
  )
}

export default React.memo(SearchBar) as typeof SearchBar
