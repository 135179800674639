import React, { useEffect, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumb } from 'antd'

import { capitalize } from 'utils/string'

export default () => {
  const { pathname } = useLocation()
  const nameArray = useMemo(() => pathname.slice(1).split('/'), [pathname])

  useEffect(() => {
    document.title =
      nameArray.map((name) => capitalize(name)).join(' / ') + ' - Debug Portal'
  }, [nameArray])

  const breadcrumbItems = nameArray.map((_, index) => {
    const url = `/${nameArray.slice(0, index + 1).join('/')}`
    const last = index === nameArray.length - 1

    return (
      <Breadcrumb.Item key={url}>
        {last ? (
          <span>{capitalize(_)}</span>
        ) : (
          <Link to={url}>{capitalize(_)}</Link>
        )}
      </Breadcrumb.Item>
    )
  })

  return <Breadcrumb>{breadcrumbItems}</Breadcrumb>
}
