import { Modal } from 'antd'
import { getAPI, setSession } from 'apiClient'
import dayjs from 'dayjs'
import React from 'react'
import ReactDOM from 'react-dom'
import { history } from 'utils/history'
import { recordCurrentURLAndRedirect } from 'utils/redirect'
import App from './App'
import { shutdownHtml } from './shutdown'

async function init() {
  if (window.location.pathname === '/dummy') {
    return
  }

  const skipClinic = window.location.search.indexOf('skip_clinic=true') >= 0
  if (skipClinic) {
    localStorage.setItem('debug_portal.skip_clinic', 'true')
  }
  const forceClinic = window.location.search.indexOf('skip_clinic=false') > 0
  if (forceClinic) {
    localStorage.removeItem('debug_portal.skip_clinic')
  }
  const skipRedirectClinic = JSON.parse(
    localStorage.getItem('debug_portal.skip_clinic') || 'false'
  )
  if (!skipRedirectClinic) {
    document.querySelector('#page_spinner')?.remove()
    document.write(shutdownHtml)
    return
  }

  if (
    history.location.pathname !== '/login' &&
    history.location.pathname !== '/auth/callback' &&
    history.location.pathname !== '/signed_out'
  ) {
    try {
      // Verify sign in status
      const sessionInfo = await getAPI().debugPortalGetBasicSession({
        noPostprocess: true
      })
      setSession(sessionInfo.data)
    } catch (e) {
      console.error(e)
      console.log(e.response)

      if (e.response?.status === 401) {
        recordCurrentURLAndRedirect('/login')
      } else {
        Modal.error({
          title: 'Connect server failed',
          content: '' + e,
          okText: 'Reload',
          onOk: () => window.location.reload()
        })
        return
      }
    }
  }

  document.querySelector('#page_spinner')?.remove()

  ReactDOM.render(<App />, document.getElementById('root'))
}

init().catch((e) => {
  Modal.error({
    title: 'Initialize failed',
    content: '' + e,
    okText: 'Reload',
    onOk: () => window.location.reload()
  })
})
