import { Button, Popover } from 'antd'
import { getAPI, useSession } from 'apiClient'
import { dbaasUrl } from 'apiClient/endpoint'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FeatureType, isFeatureEnabled } from 'utils'

function buildDashboardUrl(tenenatId: string, clusterId: string) {
  return `${dbaasUrl}/proxy/orgs/${tenenatId}/clusters/${clusterId}/pd/dashboard/`
}

function TiDBDashboardInitializer({
  tenantId,
  clusterId
}: {
  tenantId: string
  clusterId: string
}) {
  const [session] = useSession()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false)
  const handleIframeLoaded = useCallback(() => {
    iframeRef.current?.contentWindow?.postMessage(
      {
        type: 'DASHBOARD_PORTAL_EVENT',
        token: '<placeholder>'
      },
      '*'
    )
    setTimeout(() => setIframeIsLoaded(true), 100)
  }, [])

  const [canLoadIframe, setCanLoadIframe] = useState(false)
  const [isDashboardBanded, setIsDashboardBanded] = useState(false)

  useEffect(() => {
    const checkWhitelist = async () => {
      const res = await getAPI().getUserDebugFeatureList(tenantId, {
        withCredentials: false
      })
      // no need authorization
      if (!isFeatureEnabled(res.data.features, FeatureType.Authorization)) {
        setCanLoadIframe(true)
        return
      }

      try {
        await getAPI().getUserDebugAuthorizationStatus(
          tenantId,
          clusterId,
          session.email,
          {
            withCredentials: false
          }
        )
        setCanLoadIframe(true)
      } catch (e) {
        setIsDashboardBanded(true)
        setIframeIsLoaded(true)
      }
    }

    checkWhitelist()
  }, [tenantId, clusterId])

  return (
    <div>
      <Button
        type="primary"
        block
        onClick={() =>
          !isDashboardBanded &&
          window.open(buildDashboardUrl(tenantId, clusterId))
        }
        loading={!iframeIsLoaded}
        disabled={isDashboardBanded}
      >
        {isDashboardBanded
          ? 'Not authorized'
          : iframeIsLoaded
          ? 'Open'
          : 'Connecting...'}
      </Button>
      {canLoadIframe && (
        <iframe
          title="TiDB Dashboard Initializer"
          ref={iframeRef}
          onLoad={handleIframeLoaded}
          style={{
            width: 1,
            height: 1,
            opacity: 0,
            position: 'absolute',
            pointerEvents: 'none'
          }}
          src={`${buildDashboardUrl(tenantId, clusterId)}#/portal`}
        />
      )}
    </div>
  )
}

export const TiDBDashboardLink = ({
  tenantId,
  clusterId
}: {
  tenantId: string
  clusterId: string
}) => {
  return (
    <Popover
      content={
        <TiDBDashboardInitializer tenantId={tenantId} clusterId={clusterId} />
      }
      title="TiDB Dashboard"
      trigger="click"
      destroyTooltipOnHide
    >
      <a href="javascript:;">TiDB Dashboard</a>
    </Popover>
  )
}
