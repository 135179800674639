import { DownOutlined, LinkOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { getAPI, DebugPortalDashboardLink } from 'apiClient'
import React from 'react'
import { useEffect, useMemo, useState } from 'react'

export const MetricsLinks = ({
  orgId,
  clusterId
}: {
  orgId: string
  clusterId: string
}) => {
  const [links, setLinks] = useState<DebugPortalDashboardLink[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetch() {
      setLoading(true)
      const { data } = await getAPI().debugPortalGetDashboardLinks(
        orgId,
        clusterId
      )
      setLinks(data.dashboards ?? [])
      setLoading(false)
    }

    fetch()
  }, [orgId, clusterId])

  const menu = useMemo(
    () => (
      <Menu>
        {loading && <Menu.Item disabled>Loading...</Menu.Item>}
        {!loading &&
          links
            .reduce(
              (prev, current) => {
                if (prev.deduplicated.has(current.name)) {
                  return prev
                }
                prev.deduplicated.add(current.name)
                prev.arr.push(current)
                return prev
              },
              {
                deduplicated: new Set(),
                arr: []
              } as {
                deduplicated: Set<string>
                arr: DebugPortalDashboardLink[]
              }
            )
            .arr.map((link) => (
              <Menu.Item key={link.link}>
                <a target="_blank" href={link.link}>
                  {link.name} <LinkOutlined />
                </a>
              </Menu.Item>
            ))}
      </Menu>
    ),
    [links, loading]
  )
  return (
    <Dropdown overlay={menu}>
      <span>
        Metrics <DownOutlined />
      </span>
    </Dropdown>
  )
}
