import { history } from './history'

const REDIRECT_KEY = '_redirect'

export const recordCurrentURLAndRedirect = (
  to: string,
  replcae = true
): void => {
  const redirectQuery = `${history.location.pathname}${history.location.search}${history.location.hash}`
  const redirectTo = `${to}?${REDIRECT_KEY}=${encodeURIComponent(
    redirectQuery
  )}`
  replcae ? history.replace(redirectTo) : history.push(redirectTo)
}

export const recoverFromRecordOrRedirect = (
  to = '/',
  replace = true,
  navigate?: (to: string) => void
): void => {
  const searchParam = new URLSearchParams(history.location.search)
  const redirectURI = decodeURIComponent(searchParam.get(REDIRECT_KEY))
  let url: URL
  try {
    url = redirectURI
      ? new URL(`${window.location.origin}${redirectURI}`)
      : new URL(`${window.location.origin}${to}`)
  } catch (e) {
    url = new URL(`${window.location.origin}${to}`)
  }
  url.searchParams.delete(REDIRECT_KEY)
  navigate
    ? navigate(url.toString())
    : replace
    ? history.replace(url.toString())
    : history.push(url.toString())
}

export const getRedirectRecord = (): string => {
  const redirect = new URLSearchParams(history.location.search).get(
    REDIRECT_KEY
  )
  return redirect ? `${REDIRECT_KEY}=${redirect}` : ''
}
