import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { ClusterOutlined } from '@ant-design/icons'

import { capitalize } from 'utils/string'
import logo from 'images/logo.svg'
import logoThumbnail from 'images/logo-thumbnail.svg'

const { Sider } = Layout

const menuItems = [
  {
    name: 'orgs',
    icon: <ClusterOutlined />
  }
]

export default () => {
  const [collapsed, setCollapsed] = useState(false)
  const { pathname } = useLocation()

  const handleCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed)
  }

  return (
    <Sider
      className="app-sidebar"
      breakpoint="md"
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
    >
      <div className="logo">
        <img src={collapsed ? logoThumbnail : logo} alt="TiDB Cloud" />
      </div>

      <Menu
        theme="dark"
        defaultSelectedKeys={pathname.slice(1).split('/')}
        mode="inline"
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.name} icon={item.icon}>
            <Link to={`/${item.name}`}>{capitalize(item.name)}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  )
}
