import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Table, Divider, Space, Alert } from 'antd'
import { useTable, ListToolBar } from 'components'
import { capitalize } from 'utils/string'
import { getAPI, DebugPortalTenantCluster } from 'apiClient'
import { MetricsLinks } from './MetricsLinks'
import { TiDBDashboardLink } from './TiDBDashboardLink'
import { clusterLogsLink } from 'apiClient/endpoint'
import { GitOPSLinks } from './GitOPSLinks'
import { ShutdownAlert } from './ShutdownAlert'

export default function Clusters() {
  const { org_id } = useParams()
  const getData = (params?: Partial<PageInfo>) =>
    getAPI()
      .debugPortalListTenantClusters(org_id, params?.page, params?.pageSize)
      .then((resp) => resp.data)

  const { dataSource, loading, reload, paginationProps, onTableChange } =
    useTable<ListData<DebugPortalTenantCluster>>(getData, [], {
      pagination: true
    })

  const params = new URLSearchParams(window.location.search)
  const name = params.get('name')

  const renderLinksCell = useCallback(
    (_: any, record: DebugPortalTenantCluster) => {
      const logsLink = clusterLogsLink(record.id!)
      return (
        <Space split={<Divider type="vertical" />}>
          <TiDBDashboardLink tenantId={org_id} clusterId={record.id ?? ''} />
          {/* <K8sDashboardLink clusterId={record.id ?? ''} /> */}
          <MetricsLinks orgId={org_id} clusterId={record.id as string} />
          <a href={logsLink} target="_blank">
            View Logs
          </a>
          {/* <Link to={`/slowquery?orgId=${org_id}&clusterId=${record.id}`}>
            Slow Query
          </Link> */}
          {/* {compareVersion.gt(record.version, '5.1.0') && (
            <Link to={`/topsql?cluster_id=${record.id}`}>Top SQL</Link>
          )} */}
          <GitOPSLinks
            orgId={org_id}
            clusterId={record.id as string}
            orgName={name}
            branch={gitopsBranch(window.location.hostname)}
          />
        </Space>
      )
    },
    [org_id]
  )

  const columns = useMemo(() => {
    const dataCols = [
      'id',
      'provider',
      'region',
      'status',
      'profile',
      'version'
    ].map((_) => ({
      title: capitalize(_),
      dataIndex: _
    }))
    return [
      {
        title: 'Cluster Name',
        dataIndex: 'name'
      },
      ...dataCols,
      {
        title: 'Links',
        dataIndex: 'links',
        render: renderLinksCell
      }
    ]
  }, [renderLinksCell])

  const onReload = useCallback(reload, [])

  return (
    <>
      <ShutdownAlert />

      <ListToolBar
        reload={onReload}
        title={
          <>
            Clusters of{' '}
            <strong style={{ fontSize: '1.2em' }}>{name || org_id}</strong>
          </>
        }
      />

      <Table<DebugPortalTenantCluster>
        columns={columns}
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        pagination={paginationProps}
        onChange={onTableChange}
      />
    </>
  )
}

function gitopsBranch(domain: string) {
  if (domain.startsWith('debug')) {
    return 'prod'
  } else if (domain.startsWith('staging')) {
    return 'staging'
  } else {
    return 'master'
  }
}
