import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DownOutlined,
  RightOutlined
} from '@ant-design/icons'
import { createTheme, registerIcons } from 'office-ui-fabric-react/lib/Styling'
import { Customizations } from 'office-ui-fabric-react/lib/Utilities'
import { QueryClientProvider } from 'react-query'

import {
  Tenants,
  Clusters,
  SignIn,
  SignInCallback,
  SignedOut
  // SlowQueryList,
  // SlowQueryDetail,
  // TopSQL
} from 'pages'
import Layout from 'layout'
// import { useCache, CacheContext } from 'utils'
import { reactQueryClient } from 'apiClient'

import './App.less'

registerIcons({
  icons: {
    SortUp: <ArrowUpOutlined />,
    SortDown: <ArrowDownOutlined />,
    chevronrightmed: <RightOutlined />,
    tag: <DownOutlined />
  }
})

const theme = createTheme({
  defaultFontStyle: { fontFamily: 'inherit', fontSize: '1em' }
})

Customizations.applySettings({ theme })

function App() {
  // const slowQueryCacheMgr = useCache(2)
  return (
    <QueryClientProvider client={reactQueryClient}>
      <BrowserRouter>
        <Routes>
          <Navigate to="/orgs" />
          <Route path="/auth/callback" element={<SignInCallback />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/signed_out" element={<SignedOut />} />
          <Route path="/" element={<Layout />}>
            <Route path="orgs" element={<Tenants />} />
            <Route path="orgs/:org_id" element={<Clusters />} />
            {/* <CacheContext.Provider value={slowQueryCacheMgr}>
              <Route path="slowquery">
                <Route path="" element={<SlowQueryList />} />
                <Route path="detail" element={<SlowQueryDetail />} />
              </Route>
            </CacheContext.Provider> */}
            {/* <Route path="topsql" element={<TopSQL />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
