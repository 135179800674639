import React, { useCallback, useMemo } from 'react'
import { useSessionStorage } from 'react-use'
import { Link } from 'react-router-dom'
import { Table, Space, Tag, notification, Alert } from 'antd'
import { useTable, ListToolBar, SearchBar } from 'components'
import { capitalize } from 'utils/string'
import { getAPI, DebugPortalTenant } from 'apiClient'
import { FilterOption } from 'components/SearchBar'
import { ShutdownAlert } from './Detail/ShutdownAlert'

const numRegexp = /^[\d]+$/

function Tenants() {
  const [pageConfig, setPageConfig] = useSessionStorage('tenants_page_config', {
    page: 1,
    pageSize: 0
  })
  const [tenantsFilter, setTenantsFilter] = useSessionStorage<FilterOption>(
    'tenants_filter',
    { key: '', value: '' }
  )

  const getData = (params?: Partial<PageInfo>) => {
    if (
      tenantsFilter.key === 'id' &&
      !!tenantsFilter.value &&
      !numRegexp.test(tenantsFilter.value)
    ) {
      const err = new Error(`Invalid tenant id: ${tenantsFilter.value}`)
      notification.warn({
        message: err.message,
        description: 'The tenant id must be a number'
      })
      throw err
    }

    const filterValue = {
      name: undefined,
      id: undefined,
      email: undefined,
      [tenantsFilter.key]: tenantsFilter.value
    }

    return getAPI()
      .debugPortalListTenants(
        filterValue.name,
        filterValue.id,
        pageConfig.page,
        pageConfig.pageSize || params?.pageSize,
        filterValue.email,
        filter?.status as string[]
      )
      .then((resp) => resp.data)
  }

  const {
    dataSource,
    loading,
    reload,
    paginationProps,
    onTableChange,
    filter
  } = useTable<ListData<DebugPortalTenant>>(getData, [], {
    pagination: true,
    current: pageConfig.page
  })

  const renderIDCell = useCallback(
    (_: any, record: DebugPortalTenant) =>
      record.status === 'active' ? (
        <Link to={`/orgs/${record.id}?name=${record.name}`}>{record.id}</Link>
      ) : (
        record.id
      ),
    []
  )

  const renderStatusCell = useCallback((_: any, record: DebugPortalTenant) => {
    let color
    const { status } = record
    switch (status) {
      case 'cleared':
        color = 'orange'
        break
      case 'deleted':
        color = 'red'
        break
      case 'active':
        color = 'green'
        break
      default:
        color = 'blue'
    }
    return (
      <Space>
        <Tag color={color} key={status}>
          {status}
        </Tag>
      </Space>
    )
  }, [])

  const columns = useMemo(() => {
    const dataCols = ['email'].map((_) => ({
      title: capitalize(_),
      dataIndex: _
    }))
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        render: renderIDCell
      },
      {
        title: 'Org Name',
        dataIndex: 'name'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: renderStatusCell,
        filters: [
          {
            text: 'active',
            value: 'active'
          },
          {
            text: 'pending',
            value: 'pending'
          },
          {
            text: 'cleared',
            value: 'cleared'
          },
          {
            text: 'deleted',
            value: 'deleted'
          }
        ],
        filterMultiple: true,
        onFilter: (value: any, record: DebugPortalTenant) =>
          !!record.status?.includes(value)
      },
      ...dataCols,
      {
        title: 'Timezone',
        dataIndex: 'timezone',
        sortDirections: ['descend', 'ascend'] as any,
        sorter: (a: any, b: any) => a.timezone - b.timezone
      }
    ]
  }, [renderIDCell, renderStatusCell])

  const onReload = useCallback(reload, [])

  const onSearch = (query: FilterOption) => {
    setTenantsFilter(query)
    setPageConfig({ page: 1, pageSize: 0 })
    return reload()
  }

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageConfig({ page, pageSize })
  }

  return (
    <>
      <ShutdownAlert />

      <ListToolBar
        reload={onReload}
        actions={[
          <SearchBar
            defaultValue={tenantsFilter}
            options={['id', 'name', 'email']}
            fetcher={onSearch}
          />
        ]}
      />

      <Table
        columns={columns}
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        pagination={{
          ...paginationProps,
          current: pageConfig.page,
          pageSize: pageConfig.pageSize || paginationProps.pageSize,
          onChange: onPaginationChange
        }}
        onChange={onTableChange}
      />
    </>
  )
}

export default function () {
  return <Tenants />
}
