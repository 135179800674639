// inspired by https://github.com/npm/node-semver

const re = []
const src = []
const t: { [name: string]: number } = {}
let R = 0

const createToken = (name: string, value: string, isGlobal?: boolean) => {
  const index = R++
  t[name] = index
  src[index] = value
  re[index] = new RegExp(value, isGlobal ? 'g' : undefined)
}
// ## Numeric Identifier
// A single `0`, or a non-zero digit followed by zero or more digits.

createToken('NUMERICIDENTIFIER', '0|[1-9]\\d*')

// ## Main Version
// Three dot-separated numeric identifiers.

createToken(
  'MAINVERSION',
  `(${src[t.NUMERICIDENTIFIER]})\\.` +
    `(${src[t.NUMERICIDENTIFIER]})\\.` +
    `(${src[t.NUMERICIDENTIFIER]})`
)

const numeric = /^[0-9]+$/
const compareIdentifiers = (a, b) => {
  const anum = numeric.test(a)
  const bnum = numeric.test(b)

  if (anum && bnum) {
    a = +a
    b = +b
  }

  return a === b ? 0 : anum && !bnum ? -1 : bnum && !anum ? 1 : a < b ? -1 : 1
}

const compareMainVersion = (a: string, b: string) => {
  const ma = a?.trim().match(re[t.MAINVERSION])
  const mb = b?.trim().match(re[t.MAINVERSION])
  if (!ma || !mb) {
    console.error(`Invalid Version: ${a}, ${b}`)
    return false
  }

  const [, majorA, minorA, patchA] = ma
  const [, majorB, minorB, patchB] = mb

  return (
    compareIdentifiers(majorA, majorB) ||
    compareIdentifiers(minorA, minorB) ||
    compareIdentifiers(patchA, patchB)
  )
}

const eq = (a: string, b: string) => {
  return compareMainVersion(a, b) === 0
}

const gt = (a: string, b: string) => {
  return compareMainVersion(a, b) > 0
}

const gte = (a: string, b: string) => {
  return compareMainVersion(a, b) >= 0
}

const lt = (a: string, b: string) => {
  return compareMainVersion(a, b) < 0
}

const lte = (a: string, b: string) => {
  return compareMainVersion(a, b) <= 0
}

export const compareVersion = {
  eq,
  gt,
  gte,
  lt,
  lte
}
