import React, { useCallback } from 'react'
import { Avatar, Col, Dropdown, Layout, Menu, message, Row, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { getAPI, useSession } from 'apiClient'
import { ExportOutlined } from '@ant-design/icons'

const { Header } = Layout

export default React.memo(() => {
  const [session] = useSession()

  const handleLogout = useCallback(async () => {
    message.loading('Sign out in progress...', 0)
    const resp = await getAPI().debugPortalSignOut({
      session_key_as_cookie: true,
      redirect_uri: `${window.origin}/signed_out`
    })
    if (resp.data.oauth_sign_out_uri) {
      window.location.replace(resp.data.oauth_sign_out_uri)
    }
  }, [])

  const menu = (
    <Menu>
      <Menu.Item disabled>{session?.username}</Menu.Item>
      <Menu.Item icon={<ExportOutlined />} onClick={handleLogout}>
        Sign Out
      </Menu.Item>
    </Menu>
  )

  return (
    <Header className="app-header">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <h1 className="title">Debug Portal</h1>
        </Col>
        <Col span={16}>
          <div className="align-right">
            <Space size="middle">
              <Dropdown overlay={menu} placement="bottomRight">
                <Avatar className="pointer" icon={<UserOutlined />} />
              </Dropdown>
            </Space>
          </div>
        </Col>
      </Row>
    </Header>
  )
})
