import { DownOutlined, LinkOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import React from 'react'

export const GitOPSLinks = ({
  orgId,
  clusterId,
  orgName,
  branch
}: {
  orgId: string
  clusterId: string
  orgName: string
  branch: string
}) => {
  const files = [
    'meta.yaml',
    'tidb.toml',
    'pd.toml',
    'tikv.toml',
    'tiflash.toml',
    'ticdc.toml',
    'lightning.toml',
    'backup.yaml',
    'restore.yaml'
  ]

  const menu = (
    <Menu>
      {files.flatMap((fileName) => [
        <Menu.Item key={fileName}>
          <a
            target="_blank"
            href={editFileLink(fileName, branch, orgId, clusterId)}
          >
            Edit {fileName} <LinkOutlined />
          </a>
        </Menu.Item>,
        <Menu.Item key={fileName}>
          <a
            target="_blank"
            href={newFileLink(fileName, branch, orgId, clusterId, orgName)}
          >
            Add {fileName} <LinkOutlined />
          </a>
        </Menu.Item>
      ])}
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <span>
        GitOPS <DownOutlined />
      </span>
    </Dropdown>
  )
}

function newFileLink(
  file: string,
  branch: string,
  orgId: string,
  clusterId: string,
  orgName: string
) {
  const url = `https://github.com/tidbcloud/tidb-configuration/new/${branch}/tenants/tenant-${orgId}/clusters/cluster-${clusterId}/dummy?filename=${file}&value=`
  let text = `# ${orgName}`
  if (file === 'meta.yaml') {
    text += '\nupdatePolicy: Dryrun'
  }
  return url + encodeURIComponent(text)
}

function editFileLink(
  file: string,
  branch: string,
  orgId: string,
  clusterId: string
) {
  return `https://github.com/tidbcloud/tidb-configuration/edit/${branch}/tenants/tenant-${orgId}/clusters/cluster-${clusterId}/${file}`
}
