/**
 * IMPORTANT NOTE:
 * you should run `yarn run gen:api` to generate api files before you start development
 */
import axiosInstance from './axios'
import { dbaasUrl } from './endpoint'

import { CentralDebugPortalServiceApi } from './api'

export * from './api'
export * from './axios'
export * from './react_query'

let apiClientInstance: CentralDebugPortalServiceApi

export function getAPI(): CentralDebugPortalServiceApi {
  return apiClientInstance
}

function init() {
  const client = new CentralDebugPortalServiceApi(
    {
      basePath: dbaasUrl,
      baseOptions: {
        withCredentials: true
      }
    },
    undefined,
    axiosInstance
  )
  apiClientInstance = client
}

init()
