export const shutdownHtml = `
<!DOCTYPE html>
<html>
  <head>
    <link
      rel="stylesheet"
      media="screen"
      href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600"
    />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
    <title>Access Denied</title>

    <style>
      * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      html,
      body,
      div,
      span,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      abbr,
      address,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      samp,
      small,
      strong,
      sub,
      sup,
      var,
      b,
      i,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      caption,
      article,
      aside,
      canvas,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      menu,
      nav,
      section,
      summary,
      time,
      mark,
      audio,
      video {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        vertical-align: baseline;
        background: transparent;
      }

      article,
      aside,
      details,
      figcaption,
      figure,
      footer,
      header,
      hgroup,
      nav,
      section {
        display: block;
      }

      html {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        height: 100%;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      img {
        vertical-align: middle;
        max-width: 100%;
        height: auto;
        border: 0;
        -ms-interpolation-mode: bicubic;
      }

      body {
        min-height: 100%;
        -webkit-font-smoothing: subpixel-antialiased;
      }

      .clearfix {
        clear: both;
        zoom: 1;
      }
      .clearfix:before,
      .clearfix:after {
        display: block;
        height: 0;
        visibility: hidden;
      }
      .clearfix:after {
        clear: both;
      }
    </style>

    <style>
      .plain.error-page-wrapper {
        font-family: 'Source Sans Pro', sans-serif;
        padding: 0 5%;
        position: relative;
        display: flex;
        align-items: center;
      }

      .plain.error-page-wrapper .content-container {
        max-width: 400px;
        position: relative;
      }

      .plain.error-page-wrapper .head-line {
        transition: color 0.2s linear;
        font-size: 48px;
        line-height: 60px;
        color: rgba(255, 255, 255, 0.2);
        letter-spacing: -1px;
        margin-bottom: 15px;
      }
      .plain.error-page-wrapper .subheader {
        transition: color 0.2s linear;
        font-size: 30px;
        line-height: 40px;
        color: #fff;
      }
      .plain.error-page-wrapper hr {
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        border: none;
        width: 250px;
        margin: 35px 0;
      }
      .plain.error-page-wrapper .context {
        transition: color 0.2s linear;
        font-size: 18px;
        line-height: 27px;
        color: #fff;
      }
      .plain.error-page-wrapper .context p {
        margin: 0;
      }
      .plain.error-page-wrapper .context p:nth-child(n + 2) {
        margin-top: 12px;
      }
      .plain.error-page-wrapper .buttons-container {
        margin-top: 45px;
        overflow: hidden;
      }
      .plain.error-page-wrapper .buttons-container a {
        transition: color 0.2s linear, border-color 0.2s linear;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        border: 2px solid white;
        border-radius: 99px;
        padding: 8px 30px 9px;
        display: inline-block;
      }
      .plain.error-page-wrapper .buttons-container a:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
      .plain.error-page-wrapper .buttons-container a:first-child {
        margin-right: 25px;
      }

      @media screen and (max-width: 485px) {
        .plain.error-page-wrapper .header {
          font-size: 36px;
        }
        .plain.error-page-wrapper .subheader {
          font-size: 27px;
          line-height: 38px;
        }
        .plain.error-page-wrapper hr {
          width: 185px;
          margin: 25px 0;
        }

        .plain.error-page-wrapper .context {
          font-size: 16px;
          line-height: 24px;
        }
        .plain.error-page-wrapper .buttons-container {
          margin-top: 35px;
        }

        .plain.error-page-wrapper .buttons-container a {
          font-size: 13px;
          padding: 8px 0 7px;
          width: 45%;
          text-align: center;
        }
        .plain.error-page-wrapper .buttons-container a:first-child {
          margin-right: 10%;
        }
      }
    </style>

    <style>
      .background-color {
        background-color: rgba(255, 255, 255, 1) !important;
      }

      .primary-text-color {
        color: rgba(87, 87, 87, 1) !important;
      }

      .secondary-text-color {
        color: rgba(230, 64, 114, 1) !important;
      }

      .border-button {
        color: rgba(51, 81, 255, 1) !important;
        border-color: rgba(51, 81, 255, 1) !important;
      }

      #clinic_link {
        text-decoration: none;
        color: rgba(51, 81, 255, 1);
      }
    </style>
  </head>

  <body class="plain error-page-wrapper background-color background-image">
    <div class="content-container">
      <div class="head-line secondary-text-color">End of Service</div>
      <div class="subheader primary-text-color">
        This service is <strong>SHUTDOWN</strong> on 2022-09-06 and replaced by
        clinic. Will redirect to
        <a id="clinic_link" target="_blank">Clinic</a> in
        <span id="counter_down">5</span> seconds...
      </div>
      <hr />
      <div class="clearfix"></div>
      <div class="context primary-text-color">
        <p>If you meet any issue, please contact Qiaodan or Lili.</p>
      </div>
    </div>

    <script>
      function getClinicLink() {
        const host = window.location.host
        if (host === 'debug.tidbcloud.com') {
          return 'https://clinic.pingcap.com/portal/#/overview'
        }
        return 'http://clinic-staging-1072990385.us-west-2.elb.amazonaws.com:8085/portal/#/overview'
      }
      window.onload = function () {
        const clinicLink = getClinicLink()

        document.getElementById('clinic_link').setAttribute('href', clinicLink)

        const counterDownEl = document.getElementById('counter_down')
        let remainSeconds = 5
        const intervalId = setInterval(function () {
          if (remainSeconds > 0) {
            remainSeconds--
          }
          counterDownEl.innerText = remainSeconds
          if (remainSeconds === 0) {
            window.location.assign(clinicLink)
          }
        }, 1000)
      }
    </script>
  </body>
</html>
`
