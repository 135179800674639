import React from 'react'
import clsx from 'clsx'
import { Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useDebounceFn } from 'utils/hooks'

import './index.less'

interface ToolBarProps {
  className?: string
  title?: string | React.ReactNode
  actions?: React.ReactNode[]
  reload: () => Promise<void>
}

function ListToolBar({ className, title, actions, reload }: ToolBarProps) {
  const debouncedReload = useDebounceFn(reload, [], 200)

  const handleReload = () => debouncedReload.run()

  return (
    <div
      className={clsx(
        'list-toolbar-container',
        'mgb-md',
        title ? 'space-between' : 'flex-end',
        className
      )}
    >
      {title && <h3>{title}</h3>}

      <div className="actions">
        {actions?.map((action, index) => {
          return (
            <div key={index} className="action">
              {action}
            </div>
          )
        })}

        <div className="action">
          <Button icon={<ReloadOutlined />} onClick={handleReload}>
            Reload
          </Button>
        </div>
      </div>
    </div>
  )
}

// pure component (stateless component)
export default React.memo(ListToolBar) as typeof ListToolBar
