import { UserDebugFeatureListFeatures } from 'apiClient'

enum FeatureStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export enum FeatureType {
  Authorization = 'userdebug_authorization'
}

export const isFeatureEnabled = (
  list: UserDebugFeatureListFeatures[],
  feature: FeatureType
) => {
  return !!list.find(
    (item) => item.key === feature && item.status === FeatureStatus.ENABLED
  )
}
