import React from 'react'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'

import Breadcrumb from './Breadcrumb'
import Header from './Header'
import Sidebar from './Sidebar'

import './index.less'

const { Content } = Layout

const AppLayout: React.FC = () => {
  return (
    <Layout className="app-layout">
      <Sidebar />
      <Layout>
        <Header />
        <Content className="app-content">
          <Breadcrumb />
          <div className="container">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppLayout
